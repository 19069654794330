<template>
  <div class="mt-6">
    <h2 class="title is-2">
      <p>{{ $t("message.steps.zzp") }}</p>
    </h2>
    <Loader v-if="loading" />
    <Business v-else :business="business" />
    <button
      class="button is-info mb-4 mt-5"
      @click="submit"
      :disabled="!canSubmit"
    >
      <span>{{ $t("message.controls.next") }}</span>

      <span class="icon">
        <i class="fas fa-arrow-right"></i>
      </span>
    </button>
  </div>
</template>

<script>
import Business from "../Pages/Business.vue";
import Loader from "./Loader.vue";

import Api from "../api/api";
let api = new Api();

export default {
  components: {
    Business,
    Loader,
  },

  props: {
    contract_id: {
      type: Number,
    },
  },

  data() {
    return {
      loading: true,
      business: {
        email: null,
        name: null,
        coc: null,
        iban: null,
        btw_id: null,
        kor: null,
        invoice_due_days: null,
        btw_percentage: null,
        bsn: null,
      },
    };
  },
  computed: {
    canSubmit() {
      return !Object.values(this.business).some(
        (x) => x === undefined || x === null || x === ""
      );
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    required: function () {
      return false;
    },
    getData() {
      api
        .get(`/contract/invoice_details`)
        .then((response) => {
          const res = response.data;
          this.business.email = res.email;
          this.business.name = res.name;
          this.business.coc = res.coc;
          this.business.iban = res.iban;
          this.business.btw_id = res.btw_id;
          this.business.kor = res.kor;
          this.business.invoice_due_days = res.invoice_due_days;
          this.business.btw_percentage = res.btw_percentage;
          this.business.bsn = res.bsn;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    submit() {
      api
        .patch(`/contract/invoice_details`, this.business)
        .then(() => {
          this.$emit("refresh");
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
