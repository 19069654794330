<template>
  <page language-selector @switch-locale="switchLocale">
    <div class="container has-text-centered" v-if="signed">
      <h2 class="title is-2 mb-6">
        {{ $t("message.followup.success") }}
      </h2>

      <p class="subtitle">
        {{ $t("message.followup.details", { appClient: $appClient }) }}
      </p>
    </div>
    <div class="wrapper" v-else-if="loadingDocumentExpiry || !signStep">
      <loader v-if="loadingDocumentExpiry" />
      <template v-else>
        <business-form
          v-if="isZZP"
          :contract_id="contractId"
          @refresh="checkIfSignStep"
        />
        <personal-document-form
          v-else
          :contract_id="contractId"
          :nationality="nationality"
          :token="token"
          :document-expired="documentExpired"
          @refresh="checkIfSignStep"
        />
      </template>
    </div>
    <sign-contract
      v-if="signStep && !signed"
      :key="language"
      :zzp="isZZP"
      followup-contract
      :language="language"
      :setSigned="() => (signed = true)"
    />
  </page>
</template>

<script>
import Page from "./Page.vue";
import SignContract from "./SignContract.vue";
import Api from "../api/api";
import Loader from "../components/Loader.vue";
import PersonalDocumentForm from "../components/PersonalDocumentForm.vue";
import BusinessForm from "../components/BusinessForm.vue";

let api = new Api();

export default {
  props: {
    token: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      language: "nl",
      signed: false,
      loadingDocumentExpiry: true,
      documentExpired: false,
      isZZP: false,
      contractId: null,
      nationality: "NL",
      signStep: false,
    };
  },
  components: {
    SignContract,
    Page,
    Loader,
    PersonalDocumentForm,
    BusinessForm,
  },
  async created() {
    localStorage.setItem("token", this.token);

    await this.checkDocumentExpiration();
  },
  methods: {
    async checkDocumentExpiration() {
      this.loadingDocumentExpiry = true;
      const { data } = await api.get(`/document/expiration`);
      this.isZZP = data.type === 3;
      this.documentExpired = data.is_expired;
      this.contractId = data.contract_id;
      this.nationality = data.nationality;
      this.loadingDocumentExpiry = false;
      return this.documentExpired;
    },
    switchLocale(locale) {
      this.language = locale.language;
      this.$i18n.locale = locale.i18n;
    },
    async checkIfSignStep() {
      if (!(await this.checkDocumentExpiration())) {
        this.signStep = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.pdf-container {
  position: static;
}

.wrapper {
  max-width: 1024px;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0 auto;
}
</style>
